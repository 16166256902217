<template>
    <div class="reason-item-container">
        <div class="columns no-padding mar-bot-0">
            <div class="column is-8">
                <ValidationProvider
                    :rules="{ required: true }"
                    name="Lý do"
                    v-slot="{ errors }"
                    :vid="'reason-item-' + uuidGen()"
                >
                    <b-field :type="{ 'is-danger': errors[0] }" :message="errors" custom-class="is-small">
                        <b-input 
                            placeholder="Lý do"
                            v-model="data.Description"
                        ></b-input>
                    </b-field>
                </ValidationProvider>
            </div>
            <div class="column is-4">
                <ValidationProvider
                    :rules="{ required: true, min_value: 100 }"
                    name="Số tiền"
                    v-slot="{ errors }"
                    :vid="'reason-item-' + uuidGen()"
                >
                    <b-field :type="{ 'is-danger': errors[0] }" :message="errors" custom-class="is-small">
                        <currency-input 
                            class="input num-of-money"
                            :precision="0"
                            v-model="data.Money"
                        />
                    </b-field>
                </ValidationProvider>
            </div>
        </div>
        <div
            class="reason-item-delete">
            <DeleteComponent
                @onDelete="onDelete()"
            />
        </div>
    </div>
</template>

<script>
import DeleteComponent from '@/modules/common/components/DeleteComponent.vue';
import { v4 as uuidv4 } from "uuid";

export default {
    props: ["index", "data"],
    components: { DeleteComponent },
    methods: {
        uuidGen() {
          return uuidv4();
        },
        onDelete() {
            this.$emit("onDelete");
        }
    }
}
</script>

<style lang="scss">
.reason-item-container {
    .num-of-money {
        text-align: right;
    }

    .reason-item-delete {
        float: right;
    }
}

</style>