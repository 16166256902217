<template>
    <div class="reason-info-container">
        <div
            v-for="(item, idx) in data"
            :key="idx" 
            class="columns no-padding mar-bot-0"
        >
            <div class="column is-12">
                <ReasonItem
                    :index="idx"
                    :data="item"
                    @onDelete="onDeleteReason(idx)"
                ></ReasonItem>
            </div>
        </div>
        
        <div 
            class="btn-plus-reason"
            @click="onAddReason"
        >
            <img src="@/assets/img/plus.svg" alt />
            <span>Thêm lý do</span>
        </div>
    </div>
</template>

<script>
import ReasonItem from '@/modules/fundinventorysec/components/ReasonItem.vue';

export default {
    props: ["data", "titleBtnReason"],
    components: { ReasonItem },
    methods: {
        onAddReason() {
            this.$emit("onAddReason");
        },
        onDeleteReason(index) {
            this.$emit("onDeleteReason", index);
        }
    }
}
</script>

<style lang="scss">
.reason-info-container {
    .btn-plus-reason {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            margin-right: 5px;
        }

        span {
            color: $color-dark-blue;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
        }
    }
}
</style>
