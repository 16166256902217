<template>
    <div class="delete-info" @click="onDelete">
        <img src="@/assets/img/delete.svg" alt />
        <span>Xóa</span>
    </div>
</template>

<script>
export default {
    methods: {
        onDelete() {
            this.$emit('onDelete');
        }
    }
}
</script>