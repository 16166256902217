var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reason-item-container"},[_c('div',{staticClass:"columns no-padding mar-bot-0"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Loại lỗi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"custom-class":"is-small"}},[_c('v-select',{ref:"model",staticClass:"style-chooser",attrs:{"placeholder":"Chọn loại lỗi","label":"Name","options":_vm.dropdown,"reduce":function (option) { return option; }},model:{value:(_vm.data.SpendErrorChild),callback:function ($$v) {_vm.$set(_vm.data, "SpendErrorChild", $$v)},expression:"data.SpendErrorChild"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Không có kết quả phù hợp")])])],1)]}}])})],1)]),_c('div',{staticClass:"columns no-padding mar-bot-0"},[_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":{ required: false },"name":"Mã hợp đồng/bảo hiểm/VAS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"custom-class":"is-small"}},[_c('b-input',{attrs:{"type":"text","placeholder":"Mã hợp đồng/bảo hiểm/VAS"},model:{value:(_vm.data.ReferenceCode),callback:function ($$v) {_vm.$set(_vm.data, "ReferenceCode", $$v)},expression:"data.ReferenceCode"}})],1)]}}])})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 100 },"name":"Số tiền"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"custom-class":"is-small"}},[_c('currency-input',{staticClass:"input num-of-money",attrs:{"precision":0,"placeholder":"Số tiền"},model:{value:(_vm.data.Money),callback:function ($$v) {_vm.$set(_vm.data, "Money", $$v)},expression:"data.Money"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns no-padding mar-bot-0"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Chi chú"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors,"custom-class":"is-small"}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Ghi chú"},model:{value:(_vm.data.Description),callback:function ($$v) {_vm.$set(_vm.data, "Description", $$v)},expression:"data.Description"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns no-padding mar-bot-0",staticStyle:{"display":"flex"}},[_c('b-button',{staticStyle:{"margin":"auto"},attrs:{"type":"is-default","icon-left":"plus-circle"},on:{"click":function($event){return _vm.SaveData()}}},[_vm._v("Lưu lệnh chi lỗi")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }