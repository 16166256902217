
 <template>
  <div class="modal-card modal-work-flow-content">
    <section class="modal-card-body" style="text-align:center">
      <div class="text-align-first">
          <img src="@/assets/img/warning.png" />
        </div>
       <b style="color: #FF9900"
            >Bạn cần lưu lại biên bản kiểm kê</b
          >
    </section>
    <footer class="modal-card-foot">
      <div class="center-button">
        <div class="footer-border-content-validate">
          <a @click="closeFunction()">Đóng</a>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  methods:{
    closeFunction()
    {
      let _self =this;
      _self.$emit('close');
      _self.$emit('AppceptPrint');
    }
  }
}
</script>
