<template>
  <MainContent :Title="'KIỂM KÊ QUỸ VÀ SÉC TỒN'" @onBack="onBack">
    <div class="header-content">
      <div class="time">
        <img src="@/assets/img/clock.svg" alt />
        <p>
          Thời gian kiểm kê:
          <span>{{ timeStr }} </span>
        </p>
      </div>
      <div class="history" @click="onBack">
        <img src="@/assets/img/icon-history.svg" alt />
        <p>Danh sách kiểm kê</p>
      </div>
    </div>
    <div class="content-fund">
      <ValidationObserver ref="observer">
        <div class="columns">
          <div class="column is-12">
            <div class="info-block">
              <p>TỔNG KẾT QUỸ</p>
              <b-table
                :data="fundSecData.InventoryTotal"
                :bordered="isBodered"
                :narrowed="isBodered"
              >
                <b-table-column
                  field="FullName"
                  label="Tài khoản"
                  v-slot="props"
                  :td-attrs="columnTdAttrs"
                  >{{ props.row.FullName }}</b-table-column
                >
                <b-table-column
                  field="TotalMoneyActual"
                  label="Số dư sổ quỹ tại thời điểm KK (1)"
                  v-slot="props"
                  centered
                  cell-class="text-right"
                  :td-attrs="columnTdAttrs"
                  >{{ formatCurrency(props.row.FundRealTime) }}</b-table-column
                >
                <b-table-column
                  field="TotalMoneyActual"
                  label="Số dư sổ quỹ theo BBKK (2)"
                  v-slot="props"
                  centered
                  cell-class="text-right"
                  :td-attrs="columnTdAttrs"
                  >{{
                    formatCurrency(props.row.TotalMoneyCashNote)
                  }}</b-table-column
                >
                <b-table-column
                  field="TotalMoneyActual"
                  label="Số dư thực tế kiểm kê (3)"
                  v-slot="props"
                  centered
                  cell-class="text-right"
                  :td-attrs="columnTdAttrs"
                  >{{
                    formatCurrency(props.row.TotalMoneyActual)
                  }}</b-table-column
                >
                <b-table-column
                  field="Collect"
                  label="Chi chờ xử lí (4)"
                  v-slot="props"
                  centered
                  cell-class="text-right"
                  :td-attrs="columnTdAttrs"
                  >{{ formatCurrency(props.row.Collect) }}</b-table-column
                >
                <b-table-column
                  field="Spend"
                  label="Thu chờ xử lí (5)"
                  v-slot="props"
                  centered
                  cell-class="text-right"
                  :td-attrs="columnTdAttrs"
                  >{{ formatCurrency(props.row.Spend) }}</b-table-column
                >
                <b-table-column
                  field="Spend"
                  label="Quỹ thực tế so với sổ sách (6 = 3 - 2 )"
                  v-slot="props"
                  cell-class="text-right"
                  centered
                  :td-attrs="columnTdAttrs"
                  >{{ formatCurrency(props.row.TotalReality) }}</b-table-column
                >
                <b-table-column
                  field="Spend"
                  label="Lệch (7 = 3 - 2 + 4 - 5)"
                  v-slot="props"
                  cell-class="text-right"
                  :td-attrs="columnTdAttrs"
                  >{{ formatCurrency(props.row.TotalDeviant) }}</b-table-column
                >
                <b-table-column
                  field="Spend"
                  label="Thời gian kiểm kê"
                  v-slot="props"
                  :td-attrs="columnTdAttrs"
                  >{{
                    ConvertDateTime.ConvertUnixToDatetime(props.row.CreatedDate)
                  }}</b-table-column
                >
                <b-table-column
                  field="Active"
                  label="Trạng thái user"
                  v-slot="props"
                  :td-attrs="columnTdAttrs"
                  >{{ props.row.Active }}</b-table-column
                >
              </b-table>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="info-block">
              <p>BAN KIỂM KÊ</p>
              <div class="info-fund">
                <div class="columns">
                  <div class="column is-6">
                    <b-field label="Trưởng phòng giao dịch">
                      <p class="control">
                        <span class="button is-static txt-name">{{
                          fundSecData.ShopManager.Name
                        }}</span>
                      </p>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Thủ quỹ">
                      <p class="control">
                        <span class="button is-static txt-name">{{
                          fundSecData.CurrentUser.Name
                        }}</span>
                      </p>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="info-block">
              <p>KIỂM KÊ SÉC TỒN THỰC TẾ</p>
              <div class="info-fund">
                <div class="columns">
                  <div class="column is-6">
                    <ValidationProvider
                      :rules="{ required: true, min_value: 0 }"
                      name="Số lượng séc tồn"
                      v-slot="{ errors }"
                      :vid="uuidGen()"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="errors"
                        custom-class="is-small"
                        label="Số lượng séc tồn (tờ)"
                      >
                        <b-input
                          class="num-of-money"
                          type="number"
                          min="0"
                          v-model.number="fundSecData.TotalNumOfSec"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="info-block">
              <p>KIỂM KÊ TIỀN MẶT THỰC TẾ</p>
              <div class="info-fund-table">
                <div class="info-fund-table-container">
                  <div class="info-fund-table-header">
                    <div class="txt-left w-40-per"><span>MỆNH GIÁ</span></div>
                    <div class="txt-right w-20-per"><span>SỐ TỜ</span></div>
                    <div class="txt-right w-40-per">
                      <span>THÀNH TIỀN</span>
                    </div>
                  </div>

                  <div
                    v-for="(item, idx) in fundSecData.FundMoneySecList"
                    :key="idx"
                    class="info-fund-table-content"
                  >
                    <div class="txt-left w-40-per">
                      <span>{{ formatCurrency(item.Value) }}</span>
                    </div>
                    <div class="w-20-per">
                      <ValidationProvider
                        :rules="{ required: true, min_value: 0 }"
                        name="Số tờ"
                        v-slot="{ errors }"
                        :vid="uuidGen()"
                      >
                        <b-field
                          :type="{ 'is-danger': errors[0] }"
                          :message="errors"
                          custom-class="is-small"
                        >
                          <b-input
                            class="num-of-money"
                            type="number"
                            min="0"
                            v-model.number="item.NumOfMoney"
                            @input="onCalculateTotalMoney()"
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="txt-right w-40-per">
                      <span>{{
                        item.NumOfMoney >= 0
                          ? formatCurrency(item.Value * item.NumOfMoney)
                          : 0
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="info-block">
              <div class="info-fund-table">
                <div class="info-fund-table-container">
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">Chuyển quỹ chờ tích nhận</span>
                    <span class="w-40-per">
                      <span class="txt-money">{{
                        formatCurrency(fundSecData.TotalMoneyNotApcept)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer">
                    <b-table
                      :data="lstNotApcept"
                      :bordered="isBodered"
                      :narrowed="isBodered"
                    >
                      <b-table-column
                        field="SortOrder"
                        label="STT"
                        v-slot="props"
                        centered
                        >{{ props.row.SortOrder }}</b-table-column
                      >
                      <b-table-column
                        field="CreatedFullName"
                        label="Nhân viên"
                        v-slot="props"
                        centered
                        >{{ props.row.CreatedFullName }}</b-table-column
                      >
                      <b-table-column
                        field="Total"
                        label="Số tiền"
                        v-slot="props"
                        cell-class="text-right"
                        centered
                        >{{ formatCurrency(props.row.Total) }}</b-table-column
                      >
                      <b-table-column
                        field="CreatedDate"
                        label="Giờ tạo lệnh"
                        v-slot="props"
                        centered
                        >{{
                          ConvertDateTime.ConvertUnixToDatetime(
                            props.row.CreatedDate
                          )
                        }}</b-table-column
                      >
                    </b-table>
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">Chi chờ xử lý</span>
                    <span class="w-40-per">
                      <span class="txt-money">{{
                        formatCurrency(fundSecData.Collect)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer">
                    <b-table
                      :data="collect"
                      :bordered="isBodered"
                      :narrowed="isBodered"
                    >
                      <b-table-column
                        field="SortOrder"
                        label="STT"
                        v-slot="props"
                        centered
                        >{{ props.row.SortOrder }}</b-table-column
                      >
                      <b-table-column
                        field="TypeTransaction.Name"
                        label="Loại chi"
                        v-slot="props"
                        centered
                        >{{ props.row.TypeTransaction.Name }}</b-table-column
                      >
                      <b-table-column
                        field="RealMoney"
                        label="Số tiền"
                        cell-class="text-right"
                        v-slot="props"
                        centered
                        >{{
                          formatCurrency(props.row.RealMoney)
                        }}</b-table-column
                      >
                      <b-table-column
                        field="CreatedDate"
                        label="Giờ tạo lệnh"
                        v-slot="props"
                        centered
                        >{{
                          ConvertDateTime.ConvertUnixToDatetime(
                            props.row.CreatedDate
                          )
                        }}</b-table-column
                      >
                    </b-table>
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">Thu chờ xử lý</span>
                    <span class="w-40-per">
                      <span class="txt-money">{{
                        formatCurrency(fundSecData.Spend)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer">
                    <b-table
                      :data="spend"
                      :bordered="isBodered"
                      :narrowed="isBodered"
                    >
                      <b-table-column
                        field="SortOrder"
                        label="STT"
                        v-slot="props"
                        centered
                        >{{ props.row.SortOrder }}</b-table-column
                      >
                      <b-table-column
                        field="TypeTransaction.Name"
                        label="Loại thu"
                        v-slot="props"
                        centered
                        >{{ props.row.TypeTransaction.Name }}</b-table-column
                      >
                      <b-table-column
                        field="RealMoney"
                        label="Số tiền"
                        cell-class="text-right"
                        v-slot="props"
                        centered
                        >{{
                          formatCurrency(props.row.RealMoney)
                        }}</b-table-column
                      >
                      <b-table-column
                        field="CreatedDate"
                        label="Giờ tạo lệnh"
                        v-slot="props"
                        centered
                        >{{
                          ConvertDateTime.ConvertUnixToDatetime(
                            props.row.CreatedDate
                          )
                        }}</b-table-column
                      >
                    </b-table>
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">Tổng số tiền thiếu</span>
                    <span class="w-40-per">
                      <span class="txt-money">{{
                        formatCurrency(fundSecData.CollectError)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer">
                    <b-table
                      :data="lstTableSpendError"
                      :bordered="isBodered"
                      :narrowed="isBodered"
                    >
                      <b-table-column
                        field="SortOrder"
                        label="STT"
                        v-slot="props"
                        centered
                        >{{ props.row.SortOrder }}</b-table-column
                      >
                      <b-table-column
                        field="SpendErrorChild.Name"
                        label="Loại lỗi"
                        v-slot="props"
                        centered
                        >{{ props.row.SpendErrorChild.Name }}</b-table-column
                      >
                      <b-table-column
                        field="Money"
                        label="Số tiền"
                        cell-class="text-right"
                        v-slot="props"
                        centered
                        >{{ formatCurrency(props.row.Money) }}</b-table-column
                      >
                      <b-table-column
                        field="Description"
                        label="Ghi chú"
                        v-slot="props"
                        centered
                        >{{ props.row.Description }}</b-table-column
                      >
                      <b-table-column v-slot="props"
                        ><b-button
                          @click="DeleteSpend(props.row.SortOrder, 1)"
                          type="is-text"
                          size="is-small"
                          >Xóa</b-button
                        ></b-table-column
                      >
                    </b-table>
                    <div class="info-fund-table-reason" v-if="isShowButtonAdd">
                      <SpendErrorItem
                        :dropdown="spendErrorCategory"
                        :data="objSpend"
                        @SaveSpend="SaveSpend"
                      ></SpendErrorItem>
                    </div>

                    <div class="btn-plus-reason-2" @click="AddSpend(1)">
                      <img
                        src="@/assets/img/plus.svg"
                        v-if="!isShowButtonAdd"
                        alt
                      />
                      <img src="@/assets/img/delete-minus.svg" v-else alt />
                      <span>Ghi nhận số tiền thiếu</span>
                    </div>
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">Tổng số tiền thừa</span>
                    <span class="w-40-per">
                      <span class="txt-money">{{
                        formatCurrency(fundSecData.SpendErrors)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer">
                    <b-table
                      :data="lstTableRevError"
                      :bordered="isBodered"
                      :narrowed="isBodered"
                    >
                      <b-table-column
                        field="SortOrder"
                        label="STT"
                        v-slot="props"
                        centered
                        >{{ props.row.SortOrder }}</b-table-column
                      >
                      <b-table-column
                        field="SpendErrorChild.Name"
                        label="Loại lỗi"
                        v-slot="props"
                        centered
                        >{{ props.row.SpendErrorChild.Name }}</b-table-column
                      >
                      <b-table-column
                        field="Money"
                        label="Số tiền"
                        cell-class="text-right"
                        v-slot="props"
                        centered
                        >{{ formatCurrency(props.row.Money) }}</b-table-column
                      >
                      <b-table-column
                        field="Description"
                        label="Ghi chú"
                        v-slot="props"
                        centered
                        >{{ props.row.Description }}</b-table-column
                      >
                      <b-table-column v-slot="props"
                        ><b-button
                          @click="DeleteSpend(props.row.SortOrder, 2)"
                          type="is-text"
                          size="is-small"
                          >Xóa</b-button
                        ></b-table-column
                      >
                    </b-table>
                    <div class="info-fund-table-reason" v-if="isShowButtonRev">
                      <RevError
                        :dropdown="spendErrorCategory"
                        :data="objRev"
                        @SaveSpend="SaveSpend"
                      ></RevError>
                    </div>

                    <div class="btn-plus-reason-2" @click="AddSpend(2)">
                      <img
                        src="@/assets/img/plus.svg"
                        v-if="!isShowButtonRev"
                        alt
                      />
                      <img src="@/assets/img/delete-minus.svg" v-else alt />
                      <span>Ghi nhận số tiền thừa</span>
                    </div>
                  </div>
                  <div class="info-fund-table-reason">
                    <b-input
                      type="textarea"
                      v-model="fundSecData.Note"
                      placeholder="Ghi chú"
                    />
                  </div>
                  <br />
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">I. Số dư theo sổ quỹ</span>
                    <span class="w-40-per">
                      <span class="txt-money">{{
                        formatCurrency(fundSecData.TotalMoneyCashNote)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">II. Số tiền kiểm kê thực tế</span>
                    <span class="w-40-per"
                      ><span class="txt-money">{{
                        formatCurrency(fundSecData.TotalMoneyActual)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">III. Chi chờ xử lý</span>
                    <span class="w-40-per"
                      ><span class="txt-money">{{
                        formatCurrency(fundSecData.Collect)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">IV. Số tiền thiếu</span>
                    <span class="w-40-per"
                      ><span class="txt-money">{{
                        formatCurrency(fundSecData.CollectError)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">V. Thu chờ xử lý</span>
                    <span class="w-40-per"
                      ><span class="txt-money">{{
                        formatCurrency(fundSecData.Spend)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title">VI. Số tiền thừa</span>
                    <span class="w-40-per"
                      ><span class="txt-money">{{
                        formatCurrency(fundSecData.SpendErrors)
                      }}</span></span
                    >
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title"
                      >VII. Quỹ thực tế so với sổ sách (VII = II - I)</span
                    >
                    <span class="txt-color-red w-40-per"
                      ><span class="txt-money"
                        >{{
                          formatCurrency(
                            fundSecData.TotalMoneyActual -
                              fundSecData.TotalMoneyCashNote
                          )
                        }}
                      </span></span
                    >
                  </div>
                  <div class="info-fund-table-footer display-flex">
                    <span class="txt-title"
                      >VIII. Chênh lệch (VIII = II - I + III + IV - V -
                      VI)</span
                    >
                    <span class="txt-color-red w-40-per"
                      ><span class="txt-money"
                        >{{
                          formatCurrency(
                            fundSecData.TotalMoneyActual -
                              fundSecData.TotalMoneyCashNote +
                              fundSecData.Collect -
                              fundSecData.SpendErrors +
                              fundSecData.CollectError -
                              fundSecData.Spend
                          )
                        }}
                      </span></span
                    >
                  </div>
                  <div class="info-fund-table-reason" v-if="false">
                    <p class="txt-color-green">LÝ DO THỪA</p>

                    <ReasonInfo
                      :data="reasonExcessesList"
                      @onAddReason="
                        onAddReason(
                          $getConst('INVENTORY_SEC_REASON_TYPE').Excesses
                        )
                      "
                      @onDeleteReason="
                        onDeleteReason(
                          $event,
                          $getConst('INVENTORY_SEC_REASON_TYPE').Excesses
                        )
                      "
                    >
                    </ReasonInfo>
                  </div>
                  <div class="info-fund-table-reason" v-if="false">
                    <p class="txt-color-orange">LÝ DO THIẾU</p>

                    <ReasonInfo
                      :data="reasonLackList"
                      @onAddReason="
                        onAddReason($getConst('INVENTORY_SEC_REASON_TYPE').Lack)
                      "
                      @onDeleteReason="
                        onDeleteReason(
                          $event,
                          $getConst('INVENTORY_SEC_REASON_TYPE').Lack
                        )
                      "
                    >
                    </ReasonInfo>
                  </div>
                  <div class="info-fund-table-image-attach">
                    <b-upload
                      :disabled="isDisableUploadImage"
                      v-model="images"
                      multiple
                      accept=".png, .jpg, .jpeg"
                      @input="uploadImages"
                    >
                      <div class="info-fund-table-btn-image-attach">
                        <span>Ảnh đính kèm</span>
                        <img src="@/assets/img/upload.svg" alt />
                      </div>
                    </b-upload>

                    <div v-viewer="options" class="inventory-sec-popup-image-box images clearfix">
                      <ImageComponent
                        v-for="(item, index) in fundSecData.Images"
                        :key="index"
                        :file="item"
                        :idx="index"
                        @deleteFile="onDeleteImage"
                      >
                      </ImageComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="footer-block">
        <b-button class="btn" icon-left="printer" @click="confirmCustom">
          In biên bản kiểm kê
        </b-button>

        <b-button
          v-if="(fundSecData.TotalMoneyActual -
                              fundSecData.TotalMoneyCashNote +
                              fundSecData.Collect -
                              fundSecData.SpendErrors +
                              fundSecData.CollectError -
                              fundSecData.Spend) == 0"
          class="btn"
          type="is-info"
          :disabled="isDisableSave"
          @click.prevent="onSave"
        >
          Lưu kiểm kê hôm nay
        </b-button>
      </div>
    </div>

    <div id="printInventorySec" style="display: none">
      <PrintInventorySec
        v-if="!isDisableUploadImage"
        :fundSecData="fundSecData"
        :timeStr="timeStr"
        :lstTableSpendError="lstTableSpendError"
        :lstTableRevError="lstTableRevError"
        :collect="collect"
        :spend="spend"
        :RevErrorTotal="fundSecData.SpendErrors"
        :SpendErrorTotal="fundSecData.CollectError"
      />
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </MainContent>
</template>

<script>
import MainContent from "@/modules/main/components/MainContent.vue";
import SpendErrorItem from "@/modules/fundinventorysec/components/SpendErrorItem.vue";
import RevError from "@/modules/fundinventorysec/components/RevError.vue";
import PrintInventorySec from "@/modules/fundinventorysec/components/PrintInventorySec.vue";
import FundInventorySecService from "@/modules/fundinventorysec/services/FundInventorySecService.vue";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import ReasonInfo from "@/modules/fundinventorysec/components/ReasonInfo.vue";
import ImageComponent from "@/modules/common/components/ImageComponent.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import UploadAWS from "@/helpers/UploadAWS.vue";
import ConvertDateTime from "@/helpers/ConvertDatetime.vue";
import store from "@/store";
import moment from "moment";
import Enum from "@/helpers/Enum.vue";
import { v4 as uuidv4 } from "uuid";
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import WarningPrint from "@/modules/fundinventorysec/components/WarningPrint.vue";
import UserService from "@/modules/user/services/UserService.vue";
import FundInventorySecConfirmPopup from "@/modules/fundinventorysec/components/FundInventorySecConfirmPopup.vue";
import UploadStatic from "@/helpers/UploadStatic.vue";
var amzUrl = process.env.VUE_APP_STATIC_DOMAIN;

export default {
  components: {
    MainContent,
    ReasonInfo,
    ImageComponent,
    PrintInventorySec,
    SpendErrorItem,
    RevError,
  },

  data() {
    return {
       options: {
                toolbar: true,
                url: 'data-source',
            },
      isLoading: true,
      spendErrorCategory: [
        {
          Code: "01",
          Name: "Lỗi hệ thống",
        },
        {
          Code: "02",
          Name: "Lỗi bảo hiểm",
        },
        {
          Code: "03",
          Name: "Lỗi HĐ giải ngân",
        },
        {
          Code: "04",
          Name: "Lỗi VAS",
        },
        {
          Code: "05",
          Name: "Lỗi treo chờ duyệt",
        },
        {
          Code: "06",
          Name: "Lỗi tuân thủ",
        },
        {
          Code: "07",
          Name: "Lỗi thao tác người dùng",
        },
        {
          Code: "08",
          Name: "Khác",
        },
      ],
      isComponentModalActive: false,
      isBodered: true,
      SpendErrorTotal: 0,
      RevErrorTotal: 0,
      ConvertDateTime: ConvertDateTime,
      data: [],
      collect: [],
      columnsCollect: [],
      spend: [],
      columnSpend: [],
      images: [],
      currentDate: new Date(),
      isDisableSave: false,
      isDisableUploadImage: true,
      timeStr: "", //Thời gian kiểm kê quy ra chữ dùng để in
      reasonExcessesList: [], //Lý do thừa
      reasonLackList: [], //Lý do thiếu
      objSpend: {
        SpendErrorChild: {
          Code: "",
          Name: "",
        },
        Description: "",
        Money: 0,
      },
      objRev: {
        SpendErrorChild: {
          Code: "",
          Name: "",
        },
        Description: "",
        Money: 0,
      },
      fundSecData: {
        Id: 0,
        Time: 0, //Thời gian kiểm kê
        Shop: {
          Code: store.state.shopSelected.Code,
          Name: store.state.shopSelected.Name,
          Address: store.state.shopSelected.Address,
        }, //Tên PGD dùng để in
        ShopManager: {
          Code: "",
          Name: "",
        }, //TPGD
        CurrentUser: {
          Code: store.state.user.Profile.UserName,
          Name: store.state.user.Profile.FullName,
          EmployeeCode: store.state.user.Profile.EmployeeCode,
        }, //Thủ quỹ
        TotalNumOfSec: 0, //Số lượng séc tồn
        FundMoneySecList: [],
        TotalMoneyActual: 0, //Số kiểm kê thực tế
        TotalMoneyCashNote: 0, //Số dư theo sổ quỹ
        TotalMoneyDiff: 0, //Số chênh lệch theo thực thu thực chi,
        TotalMoneyTempAdvance: 0, // Tạm ứng/Hoàn tạm ứng chờ duyệt
        ReasonList: [], //Lý do chênh lệch
        Images: [], //Ảnh đính kèm
        TotalMoneyNotApcept: 0, // Số tiền quỹ chưa được tích nhận
        Collect: 0,
        Spend: 0,
        SpendError: [],
        RevTran: [],
        CollectError: 0,
        SpendErrors: 0,
        Note: null,
        FundWaitting: [],
        FundWaittingTotal: 0,
        InventoryTotal: [],
      },
      lstNotApcept: [],
      isShowButtonAdd: false,
      lstTableSpendError: [],
      lstTableRevError: [],
      isShowButtonRev: false,
      lstUser: [],
      lstBalance: [],
      filterOffice: {
        officeCode: store.state.shopSelected.Code,
        currencyCode: "VND",
        paymentType: "1",
        receiveMethod: "",
      },
      lstCashier: [],
    };
  },
  async mounted() {
    let _self = this;
    _self.getTotalNotApcept();
    _self.Get_ListRevErrorCategory();
    _self.checkURL();
    let shopHO = store.state.shopSelected.Code;
    if (store.state.shopSelected.BusinessOfficeCode == Enum.OFFICE.HO) {
      shopHO = Enum.OFFICE.HO;
    }
    let filter = {
      staffCode: store.state.user.Profile.EmployeeCode,
      officeCode: shopHO,
      currencyCode: "VND",
      paymentType: "1",
      receiveMethod: "",
    };
    let resultFund = await FundTransferService.Post_GetFundBalance(filter);
    if (resultFund && resultFund != null) {
      this.fundSecData.TotalMoneyCashNote = resultFund.balance;
    }
    if (shopHO != Enum.OFFICE.HO) {
      let resultShopManager =
        await FundInventorySecService.GetShopManagerByCode(
          store.state.shopSelected.Code
        );
      if (
        resultShopManager &&
        resultShopManager != null &&
        resultShopManager.length > 0
      ) {
        let count = 0;
        resultShopManager.forEach((element) => {
          if (count == 0 && resultShopManager.length > 1) {
            this.fundSecData.ShopManager.Code += element.UserName + "/";
            this.fundSecData.ShopManager.Name += element.FullName + "/";
          } else if (count == 0 && resultShopManager.length == 1) {
            this.fundSecData.ShopManager.Code += element.UserName;
            this.fundSecData.ShopManager.Name += element.FullName;
          } else if (count == resultShopManager.length - 1) {
            this.fundSecData.ShopManager.Code += element.UserName;
            this.fundSecData.ShopManager.Name += element.FullName;
          } else if (count != 0 && count < resultShopManager.length - 1) {
            this.fundSecData.ShopManager.Code += element.UserName + "/";
            this.fundSecData.ShopManager.Name += element.FullName + "/";
          }
          count += 1;
        });
      }
    }

    let resultDenominationsMoney =
      await FundInventorySecService.GetListCategoryDenominationsMoney();
    if (resultDenominationsMoney && resultDenominationsMoney != null) {
      this.fundSecData.FundMoneySecList = resultDenominationsMoney;
    }

    this.timeStr = this.getDayString(this.currentDate);

    //api gọi số dư chênh lệch thu/chi bên Thu/chi
    this.GetMoneyDifferenceByEmployee();
    //api Lấy số dư Tạm ứng/Hoàn tạm ứng chờ duyệt
    this.GetMoneyAddvanceDifferenceByEmployee();
    this.GetInfoBalance();
    this.GetRevProcessing();
    //this.GetBlanceOffice();
  },
  methods: {
    columnTdAttrs(row) {
      if (row.FullName === "Tổng") {
        return {
          class: "has-text-weight-bold",
        };
      }
      return null;
    },
    async GetRevProcessing() {
      let _self = this;
      _self.isLoading = true;
      const today = new Date();
      var date = ConvertDateTime.ConvertDateToString2(
        new Date(today.getFullYear(), today.getMonth(), today.getDate())
      );
      var fromDate = ConvertToDateTime.ConvertBeginDatetimeToUnix(date);

      var toDate = ConvertToDateTime.ConvertDatetimeToUnix(date);

      var result = await FundInventorySecService.Get_ListRevPending(
        store.state.shopSelected.Code,
        fromDate,
        toDate,
        store.state.user.Profile.UserName
      );
      var count = 0;
      var lst = [];
      _self.RevTran = [];
      _self.lstNotApcept = await _self.getListNotApcept();
      if (_self.lstNotApcept != null && _self.lstNotApcept.length > 0) {
        count = 0;
        lst = [];
        _self.lstNotApcept.forEach((obj) => {
          count += 1;
          obj.SortOrder = count;

          lst.push(obj);
        });
        _self.lstNotApcept = lst;
      }
      _self.data = result.Data;
      if (_self.data != null) {
        _self.collect = _self.data.filter(
          (x) =>
            x.Type == 2 &&
            x.CreatedUserName == store.state.user.Profile.UserName
        );
        _self.spend = _self.data.filter(
          (x) =>
            x.Type == 1 &&
            x.CreatedUserName == store.state.user.Profile.UserName
        );

        if (_self.collect != null && _self.collect.length > 0) {
          count = 0;
          lst = [];
          _self.collect.forEach((obj) => {
            count += 1;
            _self.fundSecData.Collect += obj.RealMoney;
            obj.SortOrder = count;
            var objTran = {
              Name: obj.TypeTransaction.Name,
              Money: obj.RealMoney,
              CreatedDate: obj.CreatedDate,
              Type: 1,
              CodeNo: obj.CodeNo,
              ReceiveCode: obj.ReceiptCode,
              Note: obj.Reason,
              AgentShopName: obj.AgentShopName,
              FullName: obj.CreatedFullName,
            };
            _self.fundSecData.RevTran.push(objTran);
            lst.push(obj);
          });
          _self.collect = lst;
        }
        if (_self.spend != null && _self.spend.length > 0) {
          lst = [];
          count = 0;
          _self.spend.forEach((obj) => {
            _self.fundSecData.Spend += obj.RealMoney;
            count += 1;
            obj.SortOrder = count;
            var objTran = {
              Name: obj.TypeTransaction.Name,
              Money: obj.RealMoney,
              CreatedDate: obj.CreatedDate,
              Type: 2,
              CodeNo: obj.CodeNo,
              ReceiveCode: obj.ReceiptCode,
              Note: obj.Reason,
              AgentShopName: obj.AgentShopName,
              FullName: obj.CreatedFullName,
            };
            _self.fundSecData.RevTran.push(objTran);
            lst.push(obj);
          });
          _self.spend = lst;
        }
      }
      _self.isLoading = false;
    },
    uuidGen() {
      return uuidv4();
    },
    onBack() {
      let url = this.$getConst("ROUTER").FundInventorySec.List;
      this.$router.push(url);
    },
    onCalculateTotalMoney() {
      this.fundSecData.TotalMoneyActual = 0;

      if (
        this.fundSecData != null &&
        this.fundSecData.FundMoneySecList != null
      ) {
        for (let i = 0; i < this.fundSecData.FundMoneySecList.length; i++) {
          let item = this.fundSecData.FundMoneySecList[i];
          this.fundSecData.TotalMoneyActual += item.Value * item.NumOfMoney;
        }
      }
    },
    onAddReason(reasonType) {
      let reasonObj = {
        Id: 0,
        Description: "",
        Money: 0,
        Type: reasonType,
      };

      if (reasonType == this.$getConst("INVENTORY_SEC_REASON_TYPE").Lack) {
        this.reasonLackList.push(reasonObj);
      } else {
        this.reasonExcessesList.push(reasonObj);
      }

      // this.fundSecData.ReasonList.push(reasonObj);
    },
    onDeleteReason(index, reasonType) {
      if (reasonType == this.$getConst("INVENTORY_SEC_REASON_TYPE").Lack) {
        this.reasonLackList.splice(index, 1);
      } else {
        this.reasonExcessesList.splice(index, 1);
      }
      // this.fundSecData.ReasonList.splice(index, 1);
    },
    async onPrint() {
      let self = this;

      self.isDisableUploadImage = false;
      await self.setReasonList();
      let isValid = await self.validateData();
      if (isValid) {
        self.$nextTick(function () {
          self.$htmlToPaper("printInventorySec");
        });
      }
    },
    confirmCustom() {
      var _self = this;
      var difTotal = _self.fundSecData.TotalMoneyActual -
                      _self.fundSecData.TotalMoneyCashNote +
                      _self.fundSecData.Collect -
                      _self.fundSecData.SpendErrors +
                      _self.fundSecData.CollectError -
                      _self.fundSecData.Spend;
      if (difTotal != 0) {
          _self.$buefy.modal.open({
          component: FundInventorySecConfirmPopup,
          hasModalCard: true,
          trapFocus: true,
          props: {},
          events: {},
        });
      } else {
          _self.$buefy.modal.open({
          component: WarningPrint,
          hasModalCard: true,
          trapFocus: true,
          props: {},
          events: {
            AppceptPrint: () => _self.onPrint(),
          },
        });
      } 
    },
    async uploadImages() {
      for (let i = 0; i < this.images.length; i++) {
        let item = this.images[i];
        let now = moment();
        let folder = "Images/InventorySec";
        let pathRoot = `${now.format("YYYY")}/${now.format("MM")}/${folder}`;
        let fileName = `${now.format("YYYYMMDD")}_${now.format(
          "HHmmss"
        )}_${now.format("X")}_${Math.floor(Math.random() * 0x10000).toString(
          16
        )}`;
        let pathFull = `${pathRoot}/${fileName}.${item.name.slice(
          item.name.lastIndexOf(".") + 1
        )}`;

        await UploadStatic.PutFileS3(
          pathFull,
          item,
          fileName,
        );
        // if (result) {
          let file = {
            Path: amzUrl + pathFull,
            FileName: fileName,
          };
          this.fundSecData.Images.push(file);
        // }
      }
      this.images = [];
      // if (this.fundSecData.Images.length > 0) {
      //   this.isDisableSave = false;
      // }
    },
    async onDeleteImage(file) {
      await UploadAWS.deleteFile(file.Path);
      for (let i = 0; i < this.fundSecData.Images.length; i++) {
        let item = this.fundSecData.Images[i];
        if (item.Path == file.Path) {
          let index = this.fundSecData.Images.indexOf(item);
          this.fundSecData.Images.splice(index, 1);
        }
      }

      // if (this.fundSecData.Images.length <= 0) {
      //   this.isDisableSave = true;
      // }
    },
    async onSave() {
      var _self = this;
      var difTotal = _self.fundSecData.TotalMoneyActual -
                      _self.fundSecData.TotalMoneyCashNote +
                      _self.fundSecData.Collect -
                      _self.fundSecData.SpendErrors +
                      _self.fundSecData.CollectError -
                      _self.fundSecData.Spend;
      if (difTotal != 0) {
          _self.$buefy.modal.open({
          component: FundInventorySecConfirmPopup,
          hasModalCard: true,
          trapFocus: true,
          props: {},
          events: {
            submit: () => _self.onSubmit(),
          },
        });
      } else {
        await _self.onSubmit();
      } 
    },
    async onSubmit() {
      var _self = this;
      let isValid = await this.validateData();
      _self.$parent.isLoading = true;
      if (_self.isDisableSave) return;
      _self.isDisableSave = true;

      setTimeout(async () => {
        try {
          if (isValid) {
            _self.isLoading = false;
            _self.fundSecData.Time = ConvertDateTime.ConvertDatetimeToUnix(
              _self.currentDate
            );
            await _self.setReasonList();
            var result = await FundInventorySecService.CreateInventorySec(
              _self.fundSecData
            );
            if (result) {
              _self.onBack();
              ToastHelper.OpenToast(
                "Tạo kiểm kê quỹ và séc tồn thành công",
                "is-success"
              );
            } else {
              ToastHelper.OpenToast(
                "Tạo kiểm kê quỹ và séc tồn thất bại",
                "is-warning"
              );
            }
            _self.isLoading = false;
          }
        } catch (ex) {
          console.log(ex);
        }

        _self.isDisableSave = false;
      }, 100);
      _self.$parent.isLoading = true;
    },
    async setReasonList() {
      this.fundSecData.ReasonList = [];
      if (this.reasonExcessesList != null) {
        for (let i = 0; i < this.reasonExcessesList.length; i++) {
          this.fundSecData.ReasonList.push(this.reasonExcessesList[i]);
        }
      }

      if (this.reasonLackList != null) {
        for (let i = 0; i < this.reasonLackList.length; i++) {
          this.fundSecData.ReasonList.push(this.reasonLackList[i]);
        }
      }
    },
    async validateData() {
      return await this.$refs.observer.validate().then(async (result) => {
        if (!result) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({ key, value }))
              .filter((error) => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);
          console.log("validateData");
          return false;
        }
        console.log("validateData", true);
        return true;
      });
    },
    async GetMoneyDifferenceByEmployee() {
      var _self = this;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      var request = {
        ShopCode: shopSelected.Code,
        EmployeeUserName: _self.fundSecData.CurrentUser.Code,
        DateFormat: "yyyy-MM-dd",
        TransactionDate: ConvertDateTime.ConvertDateToString2(new Date()),
      };
      let response =
        await FundInventorySecService.Post_GetMoneyDifferenceByEmployee(
          request
        );
      if (response) {
        _self.fundSecData.TotalMoneyDiff = response.DifferenceMoney;
      }
    },
    async GetMoneyAddvanceDifferenceByEmployee() {
      var _self = this;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      var request = {
        ShopCode: shopSelected.Code,
        EmployeeUserName: _self.fundSecData.CurrentUser.Code,
        DateFormat: "yyyy-MM-dd",
        TransactionDate: ConvertDateTime.ConvertDateToString2(new Date()),
      };
      let response =
        await FundInventorySecService.Post_GetMoneyAdvanceDifferenceByEmployee(
          request
        );
      if (response) {
        _self.fundSecData.TotalMoneyTempAdvance = response.DifferenceMoney;
      }
    },
    async getTotalNotApcept() {
      let result = await FundTransferService.GetSumTotalNotApcept(
        store.state.shopSelected.Code,
        store.state.user.Profile.UserName
      );
      if (result > 0) this.fundSecData.TotalMoneyNotApcept = result;
    },
    async getListNotApcept() {
      let result = await FundTransferService.GetSumTotalNotApceptByUser(
        store.state.shopSelected.Code,
        store.state.user.Profile.UserName
      );
      this.fundSecData.FundWaitting = result.Data;
      return result.Data;
    },
    onAddReasonSpendError() {
      let _self = this;
      _self.fundSecData.SpendError.push({
        Description: "Ghi chú",
        RealMoney: 200000,
        SortOrder: _self.fundSecData.SpendError.length + 1,
      });
    },
    SaveSpend(data) {
      let _self = this;

      if (data.Money == 0 || data.Money == null || data.Money == "") {
        ToastHelper.OpenToast("Bạn chưa nhập số tiền", "is-warning");
        return;
      }
      if (
        data.Description == 0 ||
        data.Description == null ||
        data.Description == ""
      ) {
        ToastHelper.OpenToast("Bạn chưa nhập ghi chú", "is-warning");
        return;
      }
      if (
        data.SpendErrorChild.Code == 0 ||
        data.SpendErrorChild.Code == null ||
        data.SpendErrorChild.Code == ""
      ) {
        ToastHelper.OpenToast("Bạn chưa chọn lý do", "is-warning");
        return;
      }
      _self.fundSecData.SpendErrors = 0;
      _self.fundSecData.CollectError = 0;
      if (data.Type == 1) {
        data.SortOrder = _self.lstTableSpendError.length + 1;
        _self.isShowButtonAdd = !_self.isShowButtonAdd;
      } else {
        data.SortOrder = _self.lstTableRevError.length + 1;
        _self.isShowButtonRev = !_self.isShowButtonRev;
      }

      _self.fundSecData.SpendError.push(data);
      _self.lstTableSpendError = _self.fundSecData.SpendError.filter(
        (x) => x.Type == 1
      );
      _self.lstTableRevError = _self.fundSecData.SpendError.filter(
        (x) => x.Type == 2
      );

      _self.CalcularSum();
    },
    AddSpend(Type) {
      let _self = this;
      this.objSpend = {};
      this.objRev = {};
      if (Type == 1) _self.isShowButtonAdd = !_self.isShowButtonAdd;
      else _self.isShowButtonRev = !_self.isShowButtonRev;
    },
    DeleteSpend(SortOrder, Type) {
      let _self = this;
      _self.fundSecData.SpendError = [];

      if (Type == 1) {
        _self.fundSecData.CollectError = 0;
        _self.lstTableSpendError = _self.lstTableSpendError.filter(
          (x) => x.SortOrder != SortOrder
        );
        _self.lstTableSpendError.forEach((obj) => {
          _self.fundSecData.CollectError += obj.Money;
          _self.fundSecData.SpendError.push(obj);
        });
      } else {
        _self.fundSecData.SpendErrors = 0;
        _self.lstTableRevError = _self.lstTableRevError.filter(
          (x) => x.SortOrder != SortOrder
        );
        _self.lstTableRevError.forEach((obj) => {
          _self.fundSecData.SpendErrors += obj.Money;
          _self.fundSecData.SpendError.push(obj);
        });
      }
    },
    CalcularSum() {
      let _self = this;
      _self.lstTableSpendError.forEach((obj) => {
        _self.fundSecData.CollectError += obj.Money;
      });
      _self.lstTableRevError.forEach((obj) => {
        _self.fundSecData.SpendErrors += obj.Money;
      });
    },
    async GetEmpoyee() {
      let _self = this;
      //let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let result = await UserService.Get_ListEmployeePGD(
        store.state.shopSelected.Code
      );
      _self.lstUser = result;
    },
    async GetInfoBalance() {
      let _self = this;
      //_self.GetEmpoyee();
      await _self.GetBlanceOffice();
      let isLast = await FundInventorySecService.Get_ListIsLastInven(
        store.state.shopSelected.Code
      );
      let count = 0;
      let TotalMoneyActualSum = 0;
      let Collect = 0;
      let Spend = 0;
      let TotalMoneyCashNote = 0;
      let TotalReality = 0;
      let TotalDeviant = 0;
      let TotalRealTime = 0;
      if (_self.lstCashier.length > 0) {
        _self.lstCashier.forEach((element) => {
          count += 1;
          //console.log("element ",element.cashierName);
          let user = null;

          if (isLast != null) {
            user = isLast.filter(
              (x) => x.CurrentUser.EmployeeCode == element.staffCode
            )[0];
          }
          var obj = {};
          if (
            user != null &&
            user.CurrentUser.EmployeeCode !=
              store.state.user.Profile.EmployeeCode
          ) {
            obj = {
              FundRealTime: parseFloat(element.balance),
              FullName: element.cashierName,
              TotalMoneyActual: user.TotalMoneyActual,
              Active: element.isActive ? "Active" : "InActive",
              Collect:
                user.Collect == null
                  ? 0
                  : user.Collect + user.CollectError == null
                  ? 0
                  : user.CollectError,
              Spend: user.Spend + user.SpendErrors,
              CreatedDate: user.CreatedDate,
              TotalMoneyCashNote: user.TotalMoneyCashNote,
              TotalReality: user.TotalMoneyActual - user.TotalMoneyCashNote,
              TotalDeviant:
                user.TotalMoneyActual -
                user.TotalMoneyCashNote +
                user.Collect +
                user.CollectError -
                user.Spend -
                user.SpendErrors,
            };
            TotalMoneyCashNote += user.TotalMoneyCashNote;
          } else {
            obj = {
              FundRealTime: element.balance,
              FullName: element.cashierName,
              TotalMoneyActual: 0,
              Active: element.isActive ? "Active" : "InActive",
              Collect: 0,
              Spend: 0,
              TotalMoneyCashNote: 0,
              TotalReality: 0,
              TotalDeviant: 0,
            };
          }
          TotalMoneyActualSum += obj.TotalMoneyActual;
          Collect += obj.Collect;
          Spend += obj.Spend;
          TotalReality += obj.TotalReality;
          TotalDeviant += obj.TotalDeviant;
          TotalRealTime += obj.FundRealTime;
          if (count == _self.lstCashier.length) {
            _self.fundSecData.InventoryTotal.push(obj);
            count += 1;
            if (count > _self.lstCashier.length) {
              obj = {
                FundRealTime: TotalRealTime,
                FullName: "Tổng",
                TotalMoneyActual: TotalMoneyActualSum,
                TotalMoneyCashNote: TotalMoneyCashNote,
                Active: "",
                Collect: Collect,
                Spend: Spend,
                CreatedDate: 0,
                TotalReality: TotalReality,
                TotalDeviant: TotalDeviant,
              };
            }
          }

          // console.log("obj",obj.FullName);
          _self.fundSecData.InventoryTotal.push(obj);
          //console.log("_self.fundSecData.InventoryTotal",_self.fundSecData.InventoryTotal);
        });
      }
      count = 1;
    },
    async Get_ListRevErrorCategory() {
      let _self = this;
      var result = await FundInventorySecService.Get_ListRevErrorCategory();
      _self.spendErrorCategory = result;
    },
    async GetBlanceOffice() {
      let _self = this;
      _self.GetEmpoyee();
      var result = await FundInventorySecService.Get_BalanceOffice(
        _self.filterOffice
      );
      if (result != null) {
        var cashiers = result.cashiers;
        var fil = result.cashiers;
        let lstUser = _self.lstUser;
        if (cashiers != null && cashiers.length > 0) {
          cashiers.forEach((element) => {
            var res = fil.filter((x) => x.staffCode == element.staffCode);
            var nameUser = lstUser.filter(
              (x) =>
                (x.EmployeeCode == res[0].staffCode && x.Type == 1) ||
                (x.Type != 1 &&
                  x.EmployeeCode == res[0].staffCode &&
                  res[0].balance > 0)
            );
            var name = "";
            if (nameUser != null && nameUser.length > 0)
              name = nameUser[0].FullName + " - " + res[0].staffCode;
            let obj = {};
            let total = 0;
            var active = res.filter((x) => x.isActive == 1);
            if (res != null && res.length > 1) {
              res.forEach((item) => {
                total += item.balance;
              });
              obj = {
                staffCode: res[0].staffCode,
                isActive: active != null && active.length > 0 ? 1 : 0,
                balance: Math.round(total),
                cashierName:
                  name.length > 0
                    ? name
                    : res[0].cashierName + " - " + res[0].staffCode,
              };
            } else {
              obj = {
                staffCode: res[0].staffCode,
                isActive: res[0].isActive,
                balance: Math.round(res[0].balance),
                cashierName:
                  name.length > 0
                    ? name
                    : res[0].cashierName + " - " + res[0].staffCode,
              };
            }
            var check = _self.lstCashier.filter(
              (x) => x.staffCode == obj.staffCode
            );
            if (
              (check == null || check.length == 0) &&
              (nameUser.length > 0 || res[0].balance > 0)
            )
              _self.lstCashier.push(obj);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: $color-light-gray;

  .time {
    display: flex;

    span {
      font-weight: bold;
    }
  }

  .history {
    display: flex;

    p {
      color: $color-dark-blue;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  img {
    margin-right: 10px;
  }
}
.text-right {
  text-align: right !important;
}
.display-flex {
  display: flex;
}
.content-fund {
  padding: 20px;

  .info-block {
    margin-bottom: 20px;
    .info-fund {
      background: $color-light-gray;
      padding: 20px;

      .num-of-money input {
        text-align: left;
      }

      .txt-name {
        width: 100%;
        justify-content: left;
      }
    }

    .info-fund-table {
      background: $color-light-gray;
      padding: 20px;

      .info-fund-table-container {
        background: white;
        border: 1px solid $color-EBEAEA;
        border-radius: 5px;

        .info-fund-table-header {
          margin-left: -1px;
          border-bottom: 1px solid $color-EBEAEA;
          padding: 10px 40px;
        }

        .info-fund-table-content {
          margin-left: -1px;
          border-bottom: 1px solid $color-EBEAEA;
          padding: 10px 40px;
          display: flex;
          align-items: center;
          font-size: 16px;

          .num-of-money {
            // width: 50%;
            margin-left: auto;
            margin-right: 0;
            input {
              text-align: right;
            }
          }
        }

        .txt-color-red {
          color: red;
        }

        .info-fund-table-footer {
          justify-content: flex-end;
          margin-top: 10px;
          padding: 0 20px;

          .txt-title {
            // margin-right: 20px;
            padding: 5px 0px;
          }

          .txt-money {
            background: #f9f9f9;
            border-radius: 5px;
            // width: 20%;
            text-align: right;
            margin-left: 20px;
            padding: 5px 20px;
            font-weight: bold;
            display: inline-block;
            width: calc(100% - 20px);
            font-size: 16px;

            &.txt-color-red {
              color: red;
            }
          }

          .input-reason {
            margin-right: 20px;
          }
          .header-table {
            padding: 10px 14px;
            position: relative;
            border-left: $border-default;
            border-right: $border-default;
            font-weight: 500;
            font-size: 13px;

            div.order {
              display: inline-block;
              width: 40px;
            }
            div.col {
              display: inline-block;
              width: calc(100% - 60px);
            }
            div.menu-dot {
              display: inline-block;
              width: 20px;
            }
          }

          .main-table {
            background-color: $color-white;
            border: $border-default;
            border-radius: 0 0 5px 5px;

            .item-table {
              padding: 20px 14px;
              border-bottom: 1px solid #ebedf2;
              div.order {
                display: inline-block;
                width: 40px;
              }
              div.col {
                display: inline-block;
                width: calc(100% - 60px);

                .columns {
                  align-items: center;
                  .item-user {
                    display: inline-flex;
                    align-items: center;
                    .item-user-avatar {
                      display: inline-block;
                      width: 40px;
                      height: 40px;
                      border-radius: 5px;
                      color: $color-white;
                      font-weight: bold;
                      font-size: 16px;
                      padding: 8px 9px;
                      margin-right: 8px;
                    }

                    .item-user-info {
                      display: inline-block;
                      p {
                        margin-bottom: 0;
                      }

                      span.tag-grey {
                        font-size: 12px;
                        margin-top: 0;
                        color: $color-blue;
                        font-weight: 500;
                        padding: 3px 5px;
                      }
                    }
                  }
                }
              }
              div.menu-dot {
                display: inline-block;
                width: 20px;
              }
              &:nth-of-type(even) {
                background-color: #f9f9f9;
              }
            }
          }
        }

        .info-fund-table-reason {
          padding: 20px;

          .txt-color-green {
            color: green;
          }

          .txt-color-orange {
            color: orangered;
          }
        }

        .info-fund-table-image-attach {
          padding: 20px;

          .info-fund-table-btn-image-attach {
            display: flex;
            border: 1px solid $color-DBDFE5;
            box-sizing: border-box;
            border-radius: 5px;
            box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
            width: 100%;
            padding: 5px 10px;
            span {
              padding-right: 10px;
              border-right: 1px solid $color-DBDFE5;
            }

            img {
              margin-left: 10px;
            }
          }

          .info-fund-table-image-box {
            border: 2px dashed $color-DBECFF;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 20px;
            margin-top: 15px;
          }
        }

        .w-20-per {
          display: inline-block;
          width: 20%;
        }
        .w-30-per {
          display: inline-block;
          width: 30%;
        }
        .w-40-per {
          display: inline-block;
          width: 40%;
        }

        .w-25-per {
          display: inline-block;
          width: 25%;
        }

        .w-40-per {
          width: 40%;
        }

        .w-55-per {
          display: inline-block;
          width: 55%;
        }

        .txt-left {
          text-align: left;
        }

        .txt-right {
          text-align: right;
        }
      }
    }
  }

  .footer-block {
    display: flex;
    justify-content: center;

    .btn {
      margin-right: 10px;
    }
  }
}
.btn-plus-reason-2 {
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 5px;
  }

  span {
    color: $color-dark-blue;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
}
</style>
