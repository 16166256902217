<template>
  <div class="modal-card modal-work-flow-content">
    <section class="modal-card-body">
      <div style="padding-top: 10px; color: red;">
        <div class="text-align-first">
          <img src="@/assets/img/warning.png" />
        </div>
        <div class="text-align">
          <p>Quỹ tiền mặt chưa khớp quỹ hệ thống, PGD vui lòng thực hiện:</p>  
        </div>
        <div style="margin-left: 90px;">
          <p>- Bước 1: Rà soát các giao dịch để xác định giao dịch thiếu, thực hiện bổ sung hoặc phân loại nguyên nhân thừa thiếu khi kiểm kê</p>
          <p>- Bước 2: Trường hợp xác nhận lệch nhỏ lẻ (&lt;5.000), thực hiện tạo thu/chi cân đối quỹ.</p>
          <p>Lưu ý: PGD thực hiện lưu BBKK sau khi đã kiểm tra và in BBKK.</p>
        </div>
        
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="center-button">
        <div class="footer-border-content-validate">
          <b-button @click="$emit('close')">Đóng</b-button>
          <!-- <b-button
            type="is-info"
            @click="clickSubmit"
          >
            Lưu kiểm kê
          </b-button> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    // async clickSubmit() {
    //   var _self = this;
    //   _self.$emit("submit");
    //   _self.$emit("close");
    // },
  },
};
</script>

<style lang="scss" scoped>
.modal-work-flow-content {
  width: 900px !important;
}
.custom-card-head {
  font-size: 14px;
}
.center-button {
  margin: auto;
}
.text-align {
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
}
.text-align-first {
  text-align: center;
  margin-top: 60px !important;
  color: #10a456;
}
.text-align-last {
  text-align: center;
  margin-top: 60px !important;
}
.bton-sent {
  max-width: 200px;
  border: 1px solid #d0e5da;
  margin: auto;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
}
</style>