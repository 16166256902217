<template>
  <div class="reason-item-container">
    <div class="columns no-padding mar-bot-0">
      <div class="column is-12">
        <ValidationProvider
          :rules="{ required: true }"
          name="Loại lỗi"
          v-slot="{ errors }"
        >
          <b-field
            :type="{ 'is-danger': errors[0] }"
            :message="errors"
            custom-class="is-small"
          >
            <v-select
              ref="model"
              placeholder="Chọn loại lỗi"
              class="style-chooser"
              label="Name"
              v-model="data.SpendErrorChild"
              :options="dropdown"
              :reduce="(option) => option"
            >
              <div slot="no-options">Không có kết quả phù hợp</div>
            </v-select>
            <!-- <b-select
              placeholder="Chọn loại lỗi"
              style="width: 100%"
              v-model="data.SpendErrorChild"
            >
              <option
                v-for="option in dropdown"
                :value="option"
                :key="option.Code"
                selected="01"
              >
                {{ option.Name }}
              </option>
            </b-select> -->
          </b-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="columns no-padding mar-bot-0">
      <div class="column is-6">
        <ValidationProvider
          :rules="{ required: false }"
          name="Mã hợp đồng/bảo hiểm/VAS"
          v-slot="{ errors }"
        >
          <b-field
            :type="{ 'is-danger': errors[0] }"
            :message="errors"
            custom-class="is-small"
          >
            <b-input
              type="text"
              v-model="data.ReferenceCode"
              placeholder="Mã hợp đồng/bảo hiểm/VAS"
            />
          </b-field>
        </ValidationProvider>
      </div>
      <div class="column is-6">
        <ValidationProvider
          :rules="{ required: true, min_value: 100 }"
          name="Số tiền"
          v-slot="{ errors }"
        >
          <b-field
            :type="{ 'is-danger': errors[0] }"
            :message="errors"
            custom-class="is-small"
          >
            <currency-input
              class="input num-of-money"
              :precision="0"
              v-model="data.Money"
              placeholder="Số tiền"
            />
          </b-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="columns no-padding mar-bot-0">
      <div class="column is-12">
        <ValidationProvider
          :rules="{ required: true }"
          name="Ghi chú"
          v-slot="{ errors }"
        >
          <b-field
            :type="{ 'is-danger': errors[0] }"
            :message="errors"
            custom-class="is-small"
          >
            <b-input
              type="textarea"
              v-model="data.Description"
              placeholder="Ghi chú"
            />
          </b-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="columns no-padding mar-bot-0" style="display: flex">
      <b-button
        type="is-default"
        style="margin: auto"
        @click="SaveData()"
        icon-left="plus-circle"
        >Lưu lệnh thu lỗi</b-button
      >
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: ["dropdown", "data"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    async SaveData() {
      this.data.Type = 2;
      this.$emit("SaveSpend", this.data);
    },
  },
};
</script>

<style lang="scss">
.reason-item-container {
  .num-of-money {
    text-align: right;
  }

  .reason-item-delete {
    float: right;
  }
}
.style-chooser {
  .vs__dropdown-toggle,
  .vs__dropdown-menu {
    background: #fff;
    border: solid 1px #dbdbdb !important;
    // color: #394066;
    font-family: inherit !important;
  }

  &.is-danger {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border-color: $color-red;
    }
  }
}
</style>